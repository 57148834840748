<template>
  <v-container v-if="device" fluid tag="section">
    <!--  -->
    <h1 class="text-center primary--text display-4 my-10 py-10">
      בקרוב...
    </h1>
  </v-container>
</template>

<script>
export default {
  name: 'DeviceLogs',

  data: () => ({
    //
  }),

  computed: {
    device () {
      const device = this.$store.getters['devices/getBySlug'](
        this.$route.params.slug,
      )
      return device
    },
  },

  mounted () {
    if (this.device) {
      //
      console.log(this.device)
    }
  },

  methods: {
    //
  },
}
</script>
